

































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import { ValidationRules } from '@/utils/helpers/validation-rules-helper';
import EmailComposer from '@/components/email-composer.vue';
import OrderModel from '@/models/crm/order.model';
import OrderService from '@/services/crm/order.service';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import UserContactInfo from '@/models/crm/user-contact-info.model';
import ClientModel from '@/models/crm/client.model';
import { FormHelper } from '@/utils/helpers/form-helper';
import EmailModel from '@/models/email.model';
import { IKeyValue } from '@/interfaces/key-value.interface';

@Component({
  components: {
    EmailComposer,
  },
})
export default class CrmOrderEmailView extends Vue {
  @inject(InjectionIdEnum.CrmOrderService)
  private orderService!: OrderService;

  formIsValid = true;

  emailModel: EmailModel<IKeyValue<string | number>[]> = plainToClass(EmailModel, {
    cnpj: '',
    from: null,
    to: [],
    cc: [],
    subject: null,
    sendCopy: false,
    message: null,
    metadata: [],
  });

  rules = {
    from: [[ValidationRules.arrayLength, 1], ValidationRules.arrayOfEmails],
    to: [[ValidationRules.minArrayLength, 1], ValidationRules.arrayOfEmails],
    cc: [ValidationRules.arrayOfEmails],
    subject: [ValidationRules.required],
    message: [ValidationRules.required],
  };

  @Prop({ required: true })
  orders!: OrderModel[];

  @Prop({ required: true })
  client!: ClientModel;

  @Prop({ required: true })
  userContactInfo!: UserContactInfo;

  @Emit('cancel')
  onCancel(): void {
    FormHelper.resetValidation(this.$refs.form as Vue);
  }

  mounted(): void {
    const currentDate = new Date();

    if (this.userContactInfo.email) {
      this.emailModel.from = this.userContactInfo.email;
    }

    if (this.client.email) {
      this.emailModel.to = [this.client.email];
    }

    this.emailModel.subject = this.getDefaultSubject();
    this.emailModel.message = this.getDefaultMessage();

    this.emailModel.metadata = this.orders.map((order) => {
      const filename = OrderService.generateOrderAttachmentFilename(order, currentDate);
      return {
        idDataSource: order.idDataSource,
        codEmpresa: order.codEmpresa,
        codPedido: order.codPedido,
        filename,
      };
    });
  }

  async onSend(): Promise<void> {
    if (FormHelper.validate(this.$refs.form as Vue)) {
      const loader = this.$loading.show({
        container: this.$refs.orderEmailContainer,
        canCancel: false,
      });
      try {
        this.emailModel.cnpj = this.client.cnpjCpf;

        if (this.emailModel.metadata) {
          this.emailModel.metadata = this.emailModel.metadata?.map((el) => {
            const newEl = { ...el };
            newEl.filename = newEl.filename.toString().replace('/', '_');
            return newEl;
          });
        }

        await this.orderService.sendOrderByEmail(this.emailModel);

        this.$notify.success(this.$tc('crm.orderEmailView.successfullySent', this.orders.length));

        this.$emit('complete');
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        loader.hide();
      }
    }
  }

  get filenames(): string[] {
    return (this.emailModel.metadata || []).map((item) => item.filename.toString().replace('/', '_'));
  }

  private getDefaultSubject(): string {
    const orderNumber = this.orders.map((order) => `#${order.codPedido}`).join(', ');
    return this.$tc('crm.orderEmailView.defaultSubject', this.orders.length, {
      company: this.userContactInfo.nomeEmpresa,
      orderNumber,
    });
  }

  private getDefaultMessage(): string {
    let message = `${this.$t('crm.orderEmailView.defaultMessage')}`;
    message += ` ${this.orders.map((order) => `#${order.codPedido}`).join(', ')}.`;

    if (this.userContactInfo.assinaturaEmail) {
      message += `<br><br>${this.userContactInfo.assinaturaEmail.replaceAll(/(?:\r\n|\r|\n)/g, '<br>')}`;
    }

    return message;
  }
}
